import React from 'react'

import PageTitle from '../components/page/title'

const NotFoundPage = () => (
  <div>
    <PageTitle title={'404'} />
  </div>
)

export default NotFoundPage
